import React, { Component } from 'react';
import axios from 'axios';

import ImageCollection from './ImageCollection';
import '../index.css';


export default class Successor extends Component {
    state = {
        taxon: this.props.taxon,
        rank:  this.props.rank,
        name:  this.props.name,
    }

    getSuccessors = async () => {
        let successors = [];
        await axios.get(`/api/taxon/children/${this.state.taxon}`)
            .then(res => {
                successors = res.data;
            })
            .catch(err => {
                console.log(err)
            })
        return successors;
    }

    getImages = async () => {
        let images = [];
        await axios.get(`/api/image/taxon/${this.state.taxon}/`)
            .then(res => {
                images = res.data;
                images.map(image => {
                    image.full_name = image.tx.full_name;
                    return image;
                });
            })
            .catch(err => {
                console.log(err)
            })
        return images;
    }

    updateImages = async (currentImages, newSize) => {
        if (currentImages.length > 0) {
            return currentImages;
        }
        let images = [];
        if (this.state.rank === 'species' ||
            this.state.rank === 'subspecies' ||
            this.state.name === 'UNKNOWN') {
            images = await this.getImages();
            if (images.length !== 0) {
                images.map(image => ([
                    image.url = '/image/' + image.id,
                ]));
            }
        }
        else {
            const prefix = this.state.rank === 'genus' ?
                  this.state.name.charAt(0).toUpperCase() + '. ' : ''
            images = await this.getSuccessors();
            if (images.length !== 0) {
                images.map(image => ([
                    image.url = '/gallery/index/id/' + image.id,
                    image.caption = prefix + image.name,
                    image.path_to_file = image.thumbnail,
                ]));
            }
            const unknown = await this.getImages();
            if (unknown.length > 0) {
                let image = unknown.slice(0, 1)
                image[0].url = '/unknown/' + this.state.taxon
                image[0].caption = 'Unknown'
                images = image.concat(images)
            }
        }

        return images;
    }

    displayImage = (image, imageStyle) => {
        const proxy = '/data';
        const className = image.caption === 'Unknown' ?
              'unknown-image' : 'drop-shadow'
        return (
            <img className={className} style={imageStyle}
                src={proxy + '/thumb/' + image.path_to_file}
                alt={image.full_name}
            />
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.taxon !== this.props.taxon || prevProps.rank !== this.props.rank || prevProps.name !== this.props.name) {
            this.setState({taxon: this.props.taxon, rank: this.props.rank, name: this.props.name});
        }
    }

    render() {
        return (
            <ImageCollection
                taxon={this.props.taxon}
                updateImages={this.updateImages}
                displayImage={this.displayImage}
                title={''}
                containerStyle={'taxon'}
                containerMaxHeight={'none'}
                containerMargin={172}
                imageWidth={150}
                imageHeight={150}
                caption={true}
            />
        )
    }
}
