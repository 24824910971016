import React from 'react';
import { Link } from 'react-router-dom';

import '../index.css';
import './ClassificationHierarchy.css';

function DisplaySingleClassification(props) {
    return (
        <span className={'hierarchy-text ' + props.containerStyle}>
            <Link to={'/gallery/index/id/' + props.id}>
                {props.name}
            </Link>
        </span>
    )
}

function DisplayAncestors(props) {
    const ancestors = props.ancestors.map(ancestor => (
        <span key={ancestor.id}>
        <DisplaySingleClassification
            id={ancestor.id}
            name={ancestor.name}
        />
        <span style = {{ paddingLeft: '10px', paddingRight: '10px' }}>
            {'»'}
        </span>
        </span>
    ));
    return ancestors;
}

function ClassificationHierarchy(props) {
    const N = props.hierarchy.length;
    if (N === 0) {
        return null;
    }
    return (
        <div className='hierarchy-container'>
            <DisplayAncestors
                ancestors={props.hierarchy.slice(0,-1)}
            />
            <DisplaySingleClassification
                containerStyle={'hierarchy-last-item'}
                id={props.hierarchy[N-1].id}
                name={props.hierarchy[N-1].name}
            />
        </div>
    )
}

export default ClassificationHierarchy;
