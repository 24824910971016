import React, { Component, useContext, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { CaretRight, CaretRightFill, CaretLeft, CaretLeftFill, Pencil } from 'react-bootstrap-icons';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import getHierarchyByTaxonID from './HierarchyByID';
import ClassificationHierarchy from './ClassificationHierarchy';
import DraggableComponent from './DraggableComponent';
import { ImageCommentList } from './ImageCommentList';
import { ImageCommentForm } from './ImageCommentForm';
import Loader from './Loader';
import { authHeader, hasAuthToken } from '../services/auth.service';

import { UserContext } from '../contexts/user.context';
import { ClassificationContext } from '../contexts/classification.context';

import '../index.css';
import './Image.css';

function DisplayArrow(props) {
    const [isOnArrow, setIsOnArrow] = useState(false);
    const arrow = isOnArrow ?
          props.isLeft ?
          (<CaretLeftFill color="#4f897d" size={60} className="icon" />) :
          (<CaretRightFill color="#4f897d" size={60} className="icon" />) :
          props.isLeft ?
          (<CaretLeft color="#4f897d" size={60} className="icon" />) :
          (<CaretRight color="#4f897d" size={60} className="icon" />);
    const enterArrow = (e) =>{
        setIsOnArrow(true);
    }
    const leaveArrow = (e) => {
        setIsOnArrow(false);
    }

    const style = props.isLeft ? { left: '10px' } : { right: '10px' }

    return (
        <span role="link" onMouseEnter={enterArrow} onMouseLeave={leaveArrow} className='arrow' style={style}>
            <Link to={props.url}>{arrow}</Link>
        </span>
    )
}

function DisplayArrows(props) {
    return (
        <>
            {props.image.prev !== null ?
                <DisplayArrow
                    isLeft = {true}
                    url = {'/image/' + props.image.prev}
                />
                : null
            }
            {props.image.next !== null ?
                <DisplayArrow
                    isLeft = {false}
                    url = {'/image/' + props.image.next}
                />
                : null
            }
        </>
    )
}

function DisplayRuler(props) {
    if (!props.rulerImage) {
        return null;
    }

    const rulerWidth = 20;
    const minX = props.vertical ? 0 : undefined;
    const maxX = props.vertical ? props.maxPos - rulerWidth : undefined;
    const minY = props.vertical ? undefined : 0;
    const maxY = props.vertical ? undefined : props.maxPos - rulerWidth;

    return (
        <DraggableComponent
            minX={minX}
            maxX={maxX}
            minY={minY}
            maxY={maxY}
            moveX={props.vertical}
            moveY={!props.vertical}
        >
            <img className='ruler-image'
                style={{visibility: props.visible ? 'visible' : 'hidden'}}
                src={props.rulerPath + props.rulerImage}
                alt='ruler'
            />
        </DraggableComponent>
    )

}


function DisplayImage(props) {
    const proxy = '/data';
    const user = useContext(UserContext);
    const role = user.user ? user.user.role : '';

    return (
        <table className='image'>
            <tbody>
            <tr>
                <td>
                    <div className='image-container'>
                        <img className='drop-shadow'
                            height={props.image.image_height_px}
                            width={props.image.image_width_px}
                            src={proxy + '/photo/' + props.image.path_to_file}
                            alt={props.image.full_name}
                        />
                        <DisplayRuler
                            rulerImage={props.image.ruler}
                            vertical={true}
                            maxPos={props.image.image_width_px}
                            rulerPath={proxy + '/rulers/'}
                            visible={props.vruler}
                        />
                        <DisplayRuler
                            rulerImage={props.image.ruler_horizontal}
                            vertical={false}
                            maxPos={props.image.image_height_px}
                            rulerPath={proxy + '/rulers-horizontal/'}
                            visible={props.hruler}
                        />
                    </div>
                </td>
            </tr>
            <tr>
                <td className='image-caption'>
                    <span style={{float:'left'}}>
                    {props.image.gender === 'female' ?
                        <img className='gender-icon' src = {proxy + '/icon/female_64.png'} alt='female' />
                        : props.image.gender === 'male' ?
                        <img className='gender-icon' src = {proxy + '/icon/male_64.png'} alt='male' />
                        : props.image.gender === 'pair' ?
                        <img className='gender-icon' src = {proxy + '/icon/pair_64.png'} alt='pair' />
                        : null}
                    {props.image.full_name}
                    {props.image.reliability === "doubt" ? "?" : ""}
                    </span>
                    <span style={{float:'right'}}>
                        {
                            role === "editor" ?
                                <Link to={'/image-edit/' + props.image.id}>
                                    <Pencil color="#4f897d" size={15} className="icon comment-mod" />
                                </Link>
                                : null
                        }
                    </span>
                </td>
            </tr>
            </tbody>
        </table>
    )
}

function DisplayImageInfo(props) {
    const zerosReg = /[0]+$/;

    const fields = [
        {
            key: 'Width',
            vals: [props.image.species_width?.replace(zerosReg, "").replace(/\.$/, ""), props.image.scale],
        },
        {
            key: 'Length',
            vals: [props.image.species_length?.replace(zerosReg, "").replace(/\.$/, ""), props.image.scale],
        },
        {
            key: 'Location',
            /* Regular expression is used to remove backslash escapes, but keep escaped backslashes.
               * /(?:\\) is a non-capturing group to capture the leading backslash
               * /(.) is a capturing group to capture what's following the backslash
               * /g global matching: Find all matches, not just the first.
               * $1 is referencing the content of the first capturing group (what's following the backslash).
               */
            vals: [props.image.location !== null ? props.image.location.replace(/(?:\\(.))/g, '$1') : null],
        },
        {
            key: 'Date',
            vals: [props.image.date_shot !== null ?
                   props.image.date_shot.split('T')[0]
                   : null],
        },
        {
            key: 'Legitis',
            vals: [props.image.legitis],
        },
        {
            key: 'Identification',
            vals: [props.image.det],
        },
        {
            key: 'Comments',
            vals: [props.image.comment]
        },
    ];

    const {t} = useTranslation();

    const rows = fields.map((field, fidx) => {
        const valid = field.vals.reduce(
            (prev, curr, i) => prev && curr, true);
        if (!valid) {
            return null;
        }
        const text = field.vals.map(val => ( val.toString() )).join('');
        const hbutton = props.hruler ? 'ruler-button active' : 'ruler-button inactive';
        const vbutton = props.vruler ? 'ruler-button active' : 'ruler-button inactive';
        const ruler = "Ruler"

        return (
            <tr key={fidx}>
                <td key={'image-info-key' + fidx} className='image-info-prop'>{t(field.key, field.key)}: </td>
                <td key={'image-info-val' + fidx} className='image-info-val'>
                    {text}
                    {field.key==='Width' && props.image.ruler_horizontal ?
                        <button className={hbutton}
                            onClick={props.onClickHRuler}>
                            {t(ruler, ruler)}
                        </button>
                     : field.key==='Length' && props.image.ruler ?
                        <button className={vbutton}
                            onClick={props.onClickVRuler}>
                            {t(ruler, ruler)}
                        </button>
                     : null
                    }
                </td>
            </tr>
        );
    });


    return (
        <div className='image-info'>
            <table>
                <tbody>
                { rows }
                </tbody>
            </table>
        </div>
    )
}

function UserCommentForm(props) {
    const user = useContext(UserContext);
    const {t} = useTranslation();
    if (user.user === null){
        return <p className="small text-center text-muted">{t("Please, sign in to leave comments", "Please, sign in to leave comments...")}</p>;
    }
    return (
        <ImageCommentForm
            username={user.user.username}
            onCommentSubmit={(comment) => props.onCommentSubmit(comment)}
        />
    )
}

class Image extends Component {
    static contextType = ClassificationContext;

    state = {
        image: undefined,
        hierarchy: [],
        loading: true,
        timeout: false,
        vruler: true,
        hruler: true,
        comments: [],
    }

    getComments = async (imageID) => {
        let comments = [];
        await axios.get(`/api/comments/image/${imageID}`)
            .then(res => {
                comments = res.data;
            })
            .catch(err => {
                console.log(err)
            })
        return comments;
    }

    updateComments = async (imageID) => {
        const newComments = await this.getComments(imageID);
        if (this.mounted) {
            this.setState({ comments: newComments });
        }
    }

    toggleVRuler = () => {
        this.setState( {vruler: !this.state.vruler} )
    }

    toggleHRuler = () => {
        this.setState( {hruler: !this.state.hruler} )
    }

    onCommentSubmit = (commentText) => {
        if (!hasAuthToken())
            return;

        const headers = authHeader();
        const comment = {
            image: this.state.image.id,
            text: commentText,
        }
        axios.post(`/api/comments/`, comment, {headers})
            .then(res => {
                this.setState({
                    comments: this.state.comments.concat([res.data]),
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    onCommentDelete = (comment) => {
        if (!hasAuthToken())
            return;

        const headers = authHeader();
        axios.delete(`/api/comments/${comment.id}`, {headers})
            .then(res => {
                if (res.data.status === 0) {
                    this.updateComments(this.state.image.id)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    onCommentEdit = (comment, newText) => {
        if (!hasAuthToken())
            return;

        const headers = authHeader();
        const newComment = {
            text: newText,
        }
        axios.put(`/api/comments/${comment.id}`, newComment, {headers})
            .then(res => {
                if (res.data.status === 0) {
                    this.updateComments(this.state.image.id)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    fetchContent = async() => {
        let image = undefined;
        await axios.get(`/api/image/${this.props.match.params.id}`)
            .then(res => {
                image = res.data;
            })
            .catch(err => console.log(err))
        if (image) {
            await this.updateComments(image.id);
            const taxonInfo = await getHierarchyByTaxonID(image.taxon)
            if (this.mounted) {
                this.setState({
                    image: image,
                    hierarchy: taxonInfo.hierarchy,
                    loading: false,
                });
                this.context.updateHierarchy(taxonInfo.hierarchy);
            }
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.timeout = setTimeout(() => {
            this.setState({ timeout: true })
        }, 5000);
        this.fetchContent();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            clearTimeout(this.timeout);
            this.setState({
                image: undefined,
                hierarchy: [],
                loading: true,
                vruler: true,
                hruler: true,
                comments: [],
            });
            this.fetchContent();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        this.mounted = false;
    }

    render() {
        if (this.state.loading) {
            if (!this.state.timeout)
                return null;
            return (
                <Loader />
            );
        }

        return (
            <div>
                <ClassificationHierarchy
                    hierarchy={this.state.hierarchy}
                />
                <DisplayArrows
                    image={this.state.image}
                />
                <DisplayImage
                    image={this.state.image}
                    hruler={this.state.hruler}
                    vruler={this.state.vruler}
                />
                <DisplayImageInfo
                    image={this.state.image}
                    hruler={this.state.hruler}
                    vruler={this.state.vruler}
                    onClickVRuler={() => this.toggleVRuler()}
                    onClickHRuler={() => this.toggleHRuler()}
                />
                <ImageCommentList
                    comments={this.state.comments}
                    onCommentDelete={(comment) => this.onCommentDelete(comment)}
                    onCommentEdit={(comment, newText) => this.onCommentEdit(comment, newText)}
                />
                <UserCommentForm
                    onCommentSubmit={(comment) => this.onCommentSubmit(comment)}
                />
            </div>
        )
    }
}

export default withRouter(Image);
