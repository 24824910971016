import "bootstrap/dist/css/bootstrap.min.css";

import { useContext, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Navbar, Nav, Col, Container, NavDropdown, Button} from 'react-bootstrap';
import { Globe2, PersonCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import SearchField from './SearchHeader';

import { UserContext } from '../../contexts/user.context';

import Logo from './../../logo.png'
import './Header.css'
import './SearchBar.css';

import { LoginModal } from './LoginModal';
import { AuthService, authHeader } from '../../services/auth.service';


function HeaderLogoBlock() {
    const [isOnDropdown, setIsOnDropdown] = useState(false);
    const navDropdownTitle = isOnDropdown ?
          (<Globe2 color="#4f897d" size={20} className="icon" />) :
          (<Globe2 color="grey" size={20} className="icon" />);
    const enterDropdown = (e) =>{
        setIsOnDropdown(true);
    }
    const leaveDropdown = (e) => {
        setIsOnDropdown(false);
    }

    const {t, i18n} = useTranslation();
    const user = useContext(UserContext);

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang)

        if (user && user.user && user.user.id) {
            const body = {lang : lang};
            const headers = authHeader();
            axios.post('/api/users/lang', body, { headers })
                .then(res => {
                    if (res.data.status !== 0) {
                        console.log("Failed to save language preferences...");
                    }
                })
                .catch(() => {
                    console.log("Failed to save language preferences...");
                });
        }
    }

    return (
        <Navbar.Collapse id="logo-navbar-nav">
            <Nav className="mr-auto align-middle">
                <Navbar.Brand as={Link} to='/'><img className="image" id="logo" src={Logo} alt="Logo" /></Navbar.Brand>
                <Nav.Link as={NavLink} to='/gallery/index' exact>{t('Gallery', 'Gallery')}</Nav.Link>
                <Nav.Link as={NavLink} to='/about/index'>{t('About', 'About')}</Nav.Link>
                <NavDropdown id="lang-nav-dropdown"
                    title={navDropdownTitle}
                    onMouseEnter={enterDropdown}
                    onMouseLeave={leaveDropdown}
                >
                    <NavDropdown.Item onClick={() => changeLanguage('en')}>English</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => changeLanguage('ru')}>Русский</NavDropdown.Item>
                </NavDropdown>
            </Nav>
        </Navbar.Collapse>
    )
}

function HeaderUserBlock(props) {

    const user = useContext(UserContext);
    const [modalShow, setModalShow] = useState(false);
    const authService = new AuthService();
    const handleLogout = () => { authService.logout(); user.updateContext(); }
    const [isOnDropdown, setIsOnDropdown] = useState(false);
    const enterDropdown = (e) =>{
        setIsOnDropdown(true);
    }
    const leaveDropdown = (e) => {
        setIsOnDropdown(false);
    }

    const {t} = useTranslation();

    if (user.user !== null) {
        const navDropdownTitle = isOnDropdown ?
              (<PersonCircle color="#4f897d" size={26} />) :
              (<PersonCircle color="grey" size={26} />);

        return (
            <Nav>
                <NavDropdown
                    className="ml-auto"
                    title={navDropdownTitle}
                    onMouseEnter={enterDropdown}
                    onMouseLeave={leaveDropdown}
                    id="profile-nav-dropdown">
                    <NavDropdown.Item as={NavLink} to='/profile'>
                        {t('Profile', 'Profile')}
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={handleLogout}>{t("Sign Out", "Sign Out")}</NavDropdown.Item>
                </NavDropdown>
            </Nav>
        )
    } else {
        return (
            <>
                <Button
                    variant="outline-secondary" size='sm'
                    onClick={() => setModalShow(true)}>{t("Sign In", "Sign In")}</Button>
                <LoginModal
                    show={modalShow}
                    onHide={() => {setModalShow(false);}}
                />
                <Button as={Link} to="/registration" variant = "outline-secondary" size="sm" className="ml-2">
                    {t("Register", "Register")}
                </Button>
            </>
        )
    }
}

export function Header(props) {
    return (
        <>
            <Navbar variant="light" expand="lg">
                <Container>
                    <Col>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <HeaderLogoBlock />
                    </Col>
                    <Col>
                        <SearchField />
                    </Col>
                    <Col align="right">
                        <HeaderUserBlock />
                    </Col>
                </Container>
            </Navbar>
        </>
    )
}
