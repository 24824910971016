import React from 'react';

export default class DraggableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            x: this.props.initX ? this.props.initX : 0,
            y: this.props.initY ? this.props.initY : 0,
            pageOffsetX: undefined,
            pageOffsetY: undefined,
            minX: this.props.minX,
            maxX: this.props.maxX,
            minY: this.props.minY,
            maxY: this.props.maxY,
            moveX: this.props.moveX,
            moveY: this.props.moveY,
        };
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.initX !== this.props.initX ||
            prevProps.initY !== this.props.initY ||
            prevProps.minX !== this.props.minX ||
            prevProps.maxX !== this.props.maxX ||
            prevProps.minY !== this.props.minY ||
            prevProps.maxY !== this.props.maxY
           ) {
            this.setState ({
                x: this.props.initX ? this.props.initX : 0,
                y: this.props.initY ? this.props.initY : 0,
                minX: this.props.minX,
                maxX: this.props.maxX,
                minY: this.props.minY,
                maxY: this.props.maxY,
            })
        }
    }

    /*
    static getDerivedStateFromProps(nextProps, prevState) {
        //prevState.x = nextProps.initX ? nextProps.initX : 0;
        //prevState.y = nextProps.initY ? nextProps.initY : 0;
        prevState.minX = nextProps.minX;
        prevState.maxX = nextProps.maxX;
        prevState.minY = nextProps.minY;
        prevState.maxY = nextProps.maxY;

        return prevState;
    }
    */

    capValue = (val, minVal, maxVal) => {
        if (!isNaN(minVal)) {
            val = Math.max(val, minVal);
        }
        if (!isNaN(maxVal)) {
            val = Math.min(val, maxVal);
        }
        return val;
    }

    onStart(e) {
        this.setState({
            pageOffsetX: e.pageX - this.state.x,
            pageOffsetY: e.pageY - this.state.y,
        });
    }

    onMove(e) {
        const x = this.capValue(this.state.moveX ? e.pageX - this.state.pageOffsetX : this.state.x,
                                this.state.minX, this.state.maxX);
        const y = this.capValue(this.state.moveY ? e.pageY - this.state.pageOffsetY : this.state.y,
                                this.state.minY, this.state.maxY);

        this.setState({
            x: x,
            y: y,
        });

        if (this.props.updateComponentPosition)
            this.props.updateComponentPosition(x, y);
    }

    onMouseDown(e) {
        if (e.button !== 0) return;
        this.onStart(e);
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
        e.preventDefault();
    }

    onMouseUp(e) {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
        e.preventDefault();
    }

    onMouseMove(e) {
        this.onMove(e);
        e.preventDefault();
    }

    onTouchStart(e) {
        this.onStart(e.touches[0]);
        document.addEventListener('touchmove', this.onTouchMove, {passive: false});
        document.addEventListener('touchend', this.onTouchEnd, {passive: false});
        e.preventDefault();
    }

    onTouchMove(e) {
        this.onMove(e.touches[0]);
        e.preventDefault();
    }

    onTouchEnd(e) {
        document.removeEventListener('touchmove', this.onTouchMove);
        document.removeEventListener('touchend', this.onTouchEnd);
        e.preventDefault();
    }

    render() {
        return (
            <div
                onMouseDown={this.onMouseDown}
                onTouchStart={this.onTouchStart}
                style={{
                    position: 'absolute',
                    left: this.state.x,
                    top: this.state.y,
                    touchAction: 'none'
                }}
                ref={(div) => { this.handle = div; }}
            >
                {this.props.children}
            </div>
        );
    }
}
