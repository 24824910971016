import { useTranslation } from 'react-i18next';
import './About.css';

function About(props) {
    const {t} = useTranslation();
    const proxy = '/data';
    return (
        <table id="about">
            <tbody>
            <tr>
                <td>
                    <img src={proxy+"/image/intro.png"} alt="Alexander Slutsky"/>
                </td>
                <td id="tdInfo"> <p className="aboutText"> {t("About message", "About message")} </p>
                    <br />
                    <p className="aboutText"> {t("Reach me", "Reach me")} <a href="mailto:alslutsky@gmail.com">alslutsky@gmail.com</a>.</p>
                    <br />
                    <h4 className="hCaption">{t("My photos", "My photos")}</h4>
                    <ul className="ulLinks">
                        <li>
                            <a href="http://macroclub.ru/gallery/showgallery.php/ppuser/4590/cat/500">Macroclub</a>
                        </li>
                        <li>
                            <a href="http://macroid.ru/showgallery.php?thumbsonly=2&perpage=60&cat=500&ppuser=4590&thumbcheck=2&page=1&sortby=&sorttime=0&way=&date=0">Macroid</a>
                        </li>
                        <li>
                            <a href="http://www.photosight.ru/users/134721">Photosight</a>
                        </li>
                    </ul>
                </td>
            </tr>
            </tbody>
        </table>

    )
}

export default About;