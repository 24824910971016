import { Component } from 'react';
import { withTranslation } from 'react-i18next';

class ImageCommentFormBase extends Component {
    state = {
        username: this.props.username,
        text: '',
    }

    handleTextChange = (e) => {
        this.setState({text: e.target.value});
    }

    handleSubmit = (e) => {
        e.preventDefault();
        var text = this.state.text.trim();
        if (!text || !this.state.username) {
            return;
        }
        this.props.onCommentSubmit(text);
        this.setState({text: ''});
    }

    render() {
        return (
            <form className="commentForm" onSubmit={this.handleSubmit}>
                <div>
                <textarea
                    rows={3}
                    placeholder={this.props.t("Write a comment", "Write a comment")}
                    value={this.state.text}
                    onChange={this.handleTextChange}
                />
                </div>
                <div style={{textAlign: 'right'}}>
                <input
                    type="submit"
                    value={this.props.t("Post", "Post")}
                    disabled = {(this.state.text && this.state.username)? "" : "disabled"}
                />
                </div>

            </form>
        );
    }
}

export const ImageCommentForm =  withTranslation()(ImageCommentFormBase);
