import { useContext, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { Trash, Pencil } from 'react-bootstrap-icons';

import { UserContext } from '../contexts/user.context';
import { useTranslation } from 'react-i18next';

function EditCommentModal(props) {
    const {t} = useTranslation();
    const onSubmit = (values) => {
        props.onSubmit(values.commentText);
    };
    const initText = props.comment ?
          props.comment.text.replace(/(?:\\(.))/g, '$1')
          : ""

    return (
        <Modal
            {...props}
            style={{backgroundColor: 'rgba(0,0,0,0.1)'}}
            animation={false}
            backdrop={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{t("Edit comment", "Edit comment")}</Modal.Title>
            </Modal.Header>

            <Formik
                onSubmit={onSubmit}
                initialValues={{ commentText: initText }}
            >
            {( {handleSubmit,
                values,
                handleChange}) => {
                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Form.Group controlId="formCommentText">
                                <Form.Control as="textarea"
                                    rows={3}
                                    name="commentText"
                                    placeholder={t("Enter comment","Enter comment")}
                                    onChange={handleChange}
                                    value={values.commentText}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer style={{justifyContent: 'center'}}>
                            <Button variant="primary" type="submit">
                                {t("Submit", "Submit")}
                            </Button>
                            <div style={{ width: "250px" }}></div>
                            <Button variant="outline-secondary" onClick={props.onHide}>
                                {t("Cancel", "Cancel")}
                            </Button>
                        </Modal.Footer>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
}

function DeleteCommentModal(props) {
    const {t} = useTranslation();
    const confirmation = "Are you sure you want to delete this comment?"
    return (
        <Modal
            {...props}
            style={{backgroundColor: 'rgba(0,0,0,0.1)'}}
            animation={false}
            backdrop={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{t("Delete comment?", "Delete comment?")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t(confirmation, confirmation)}</Modal.Body>
            <Modal.Footer style={{justifyContent: 'center'}}>
                <Button variant="primary" onClick={props.onSubmit}>
                    {t("Delete", "Delete")}
                </Button>
                <div style={{ width: "250px" }}></div>
                <Button variant="outline-secondary" onClick={props.onHide}>
                    {t("Cancel", "Cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export function ImageCommentList(props) {
    const user = useContext(UserContext);
    const username = user.user ? user.user.username : '';
    const role = user.user ? user.user.role : '';

    const [idxCommentToDelete, setIdxCommentToDelete] = useState(-1);
    const handleCloseDelete = () => {
        setIdxCommentToDelete(-1);
    }
    const handleShowDelete = (idx) => {
        setIdxCommentToDelete(idx);
    }
    const confirmDelete = (idx) => {
        props.onCommentDelete(props.comments[idx]);
        handleCloseDelete();
    };

    const [idxCommentToEdit, setIdxCommentToEdit] = useState(-1);
    const handleCloseEdit = () => {
        setIdxCommentToEdit(-1);
    }
    const handleShowEdit = (idx) => {
        setIdxCommentToEdit(idx);
    }
    const confirmEdit = (idx, newText) => {
        props.onCommentEdit(props.comments[idx], newText);
        handleCloseEdit();
    };

    return (
            <>
            <DeleteCommentModal
                show={idxCommentToDelete >= 0}
                onHide={handleCloseDelete}
                onSubmit={() => confirmDelete(idxCommentToDelete)}
            />
            <EditCommentModal
                comment={props.comments[idxCommentToEdit]}
                show={idxCommentToEdit >= 0}
                onHide={handleCloseEdit}
                onSubmit={(newText) => confirmEdit(idxCommentToEdit, newText)}
            />
            <ul id="ksl-comments-list">
                {props.comments.map((comment, idx) => {
                    const isAuthor = username === comment.us.username;
                    const isEditor = role === "editor"
                    const commentText = comment.text !== null ?
                        comment.text.replace(/(?:\\(.))/g, '$1')
                        : ""
                    const formattedComment = commentText.split('\n').map((line,idx) => {
                        return (
                                <p className="ksl-comment-text" key={idx}>{line}</p>
                        )
                    });

                    return (
                        <li className="ksl-comment" id={`com${comment.id}>`} key={comment.id}>
                            <div className="ksl-comment-caption image-cell">
                                <p className="ksl-comment-caption">
                                    <span className="ksl-comment-author">{comment.us.username}</span>
                                    <span className="ksl-comment-date">
                                        {new Intl.DateTimeFormat('en-GB', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric'
                                        }).format(new Date(comment.date))}
                                        <span style={{padding:'2px'}}> </span>
                                        {isAuthor || isEditor ?
                                         <>
                                         <Trash color="#4f897d" size={15} className="icon comment-mod" onClick={() => handleShowDelete(idx)} />
                                         <span style={{padding:'1px'}}> </span>
                                         <Pencil color="#4f897d" size={15} className="icon comment-mod" onClick={() => handleShowEdit(idx)} />
                                         </>
                                         : null}
                                    </span>
                                </p>
                            </div>
                            <div className="ksl-comment-text">
                                {formattedComment}
                            </div>
                        </li>
                    )
                })}
            </ul>
            </>
    )
}
