import axios from 'axios';

export default async function getHierarchyByTaxonID(id) {
    let taxon = 0
    if (id) {
        taxon = Number(id);
    }
    let rank = 'phylum';
    let name = 'Arthropoda';
    let hierarchy = [];

    if (taxon !== 0) {
        await axios.get(`/api/taxon/hierarchy/${id}`)
            .then(res => {
                hierarchy = res.data;
            })
            .catch( err => console.log(err) )
        if (hierarchy.length > 0) {
            const current = hierarchy[hierarchy.length - 1];
            taxon = current.id;
            rank = current.rank;
            name = current.name;
        }
        else {
            await axios.get(`/api/taxon/id/${id}`)
                .then(res => {
                    const animalia = res.data;
                    taxon = animalia.id;
                    rank = animalia.rank;
                    name = animalia.name;
                })
                .catch( err => console.log(err) )
        }
    }

    hierarchy = [{
        id: 0,
        rank: 'phylum',
        name: 'Arthropoda',
    }].concat(hierarchy);

    return ({
        taxon: taxon,
        rank: rank,
        name: name,
        hierarchy: hierarchy,
    });
}
