import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import getHierarchyByTaxonID from './HierarchyByID';
import ClassificationHierarchy from './ClassificationHierarchy';
import Successor from './Successor';
import RecentImage from './RecentImage';
import RecentComment from './RecentComment';
import Loader from './Loader';

import { ClassificationContext } from '../contexts/classification.context';

import '../index.css';

class Gallery extends Component {
    static contextType = ClassificationContext;

    state = {
        taxon: undefined,
        rank: undefined,
        name: undefined,
        hierarchy: [],
        loading: true,
        timeout: false,
    }

    fetchContent = async () => {
        var id = '0'
        if (this.props.match.params.id) {
            id = this.props.match.params.id
        }
        const taxonInfo = await getHierarchyByTaxonID(id);

        if (this.mounted) {
            this.setState({
                taxon: taxonInfo.taxon,
                rank: taxonInfo.rank,
                name: taxonInfo.name,
                hierarchy: taxonInfo.hierarchy,
                loading: false,
            })

            this.context.updateHierarchy(taxonInfo.hierarchy);
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            clearTimeout(this.timeout);
            this.fetchContent();
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.timeout = setTimeout(() => {
            this.setState({ timeout: true })
        }, 5000);
        this.fetchContent();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        this.mounted = false;
    }

    render() {
        if (this.state.loading) {
            if (!this.state.timeout)
                return null;
            return (
                <Loader />
            );
        }

        return (
            <div>
                <ClassificationHierarchy
                    hierarchy={this.state.hierarchy}
                />
                <Successor
                    taxon={this.state.taxon}
                    name={this.state.name}
                    rank={this.state.rank}
                />
                {this.state.rank === 'species' ? null :
                    <RecentImage
                        rank={this.state.rank}
                        taxon={this.state.taxon}
                        titleURL={'/taxon-images/' + this.state.taxon}
                        containerStyle={'grey-background'}
                        containerMaxHeight={350}
                        containerMargin={192}
                    />
                }
                <RecentComment
                    rank={this.state.rank}
                    taxon={this.state.taxon}
                    titleURL={'/taxon-comments/' + this.state.taxon}
                    containerStyle={'grey-background'}
                    containerMaxHeight={350}
                />
            </div>
        )
    }
}

export default withRouter(Gallery);
