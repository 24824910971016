import React from 'react';
import { useState, useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from "react-bootstrap/Tooltip";

import './TooltipComponent.css'

export default function TooltipComponent(props) {

    const [show, setShow] = useState(false);
    const target = useRef(null);

    if (!props.name) {
        return props.children;
    }

    let name = props.name
    if (name.slice(-5, name.length) === "doubt")
        name = name.slice(0, -5);
    name = name.trim();
    if (name.slice(-1) === ',')
        name = name.slice(0, name.length - 1);

    const gender = props.gender ?
        props.gender === 'male' || props.gender === 'female' ? ', ' + props.gender : ''
        : '';

    const doubt = props.reliability && props.reliability === 'doubt' ? '?' : '';

    return (
        <div ref={target}
             onMouseOver={() => setShow(true)}
             onMouseOut={() => setShow(false)}>
            <Overlay transition={false}
                     target={target.current}
                     show={show}
                     placement="bottom">
                <Tooltip id='tooltip-bottom'>
                    {doubt}
                    {name}
                    {gender}</Tooltip>
            </Overlay>
            {props.children}
        </div>
    )
}
