import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import getHierarchyByTaxonID from './HierarchyByID';
import ClassificationHierarchy from './ClassificationHierarchy';
import Pagination from './Pagination';
import CommentCollection from './CommentCollection';
import Loader from './Loader';

import { ClassificationContext } from '../contexts/classification.context';

import '../index.css';


class TaxonComment extends Component {
    static contextType = ClassificationContext;

    state = {
        rank: undefined,
        taxon: undefined,
        hierarchy: [],
        loading: true,
        timeout: false,
        currentPage: 1,
        totalComments: undefined,
        pageLimit: 20,
    }

    getComments = async (offset) => {
        let comments = [];
        await axios.get(`/api/comments/${this.state.rank}/${this.state.taxon}/${offset}`)
            .then(res => {
                comments = res.data.slice;
                if (this.mounted) {
                    this.setState({
                        totalComments: res.data.total,
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
        return comments;
    }

    getTotalComments = async () => {
        let totalComments = 0;
        await axios.get(`/api/comments/${this.state.rank}/${this.state.taxon}/0`)
            .then(res => {
                totalComments = res.data.total;
            })
            .catch(err => {
                console.log(err)
            })
        return totalComments;
    }

    updateComments = async (currentComments) => {
        if (currentComments.length > 0) {
            return currentComments;
        }

        //await new Promise(r => setTimeout(r, 5000));

        const comments = await this.getComments((this.state.currentPage - 1) * this.state.pageLimit);
        return comments;
    }

    onPageChanged = data => {
        this.setState({
            currentPage: data.currentPage,
        });
    }

    updateCurrentPage = () => {
        var currentPage = 1
        if (this.props.match.params.page && isFinite(this.props.match.params.page)) {
            currentPage = Number(this.props.match.params.page);
        }
        this.setState({
            currentPage: currentPage,
        })
    }

    fetchContent = async () => {
        var id = '0'
        if (this.props.match.params.id) {
            id = this.props.match.params.id
        }

        const taxonInfo = await getHierarchyByTaxonID(id);

        this.setState({
            taxon: taxonInfo.taxon,
            rank: taxonInfo.rank,
            hierarchy: taxonInfo.hierarchy,
        }, async () => {
            const totalComments = await this.getTotalComments();
            this.setState({
                totalComments: totalComments,
                loading: false,
            })
        })

        this.context.updateHierarchy(taxonInfo.hierarchy);
        this.updateCurrentPage();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            clearTimeout(this.timeout);
            this.fetchContent();
        }
        else if (prevProps.match.params.page !== this.props.match.params.page) {
            clearTimeout(this.timeout);
            this.updateCurrentPage();
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.timeout = setTimeout(() => {
            this.setState({ timeout: true })
        }, 5000);

        this.fetchContent();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        this.mounted = false;
    }

    render() {
        if (this.state.loading) {
            if (!this.state.timeout)
                return null;
            return (
                <Loader />
            );
        }

        return (
            <div>
                <ClassificationHierarchy
                    hierarchy={this.state.hierarchy}
                />
                <div className="w-100 px-4 py-2 d-flex flex-row flex-wrap align-items-center justify-content-center">
                    <Pagination
                        label={"TaxonCommentPaginationTop"}
                        totalRecords={this.state.totalComments}
                        pageLimit={this.state.pageLimit}
                        pageNeighbours={0}
                        currentPage={this.state.currentPage}
                        pageLink={'/taxon-comments/' + this.state.taxon + '/'}
                        onPageChanged={this.onPageChanged} />
                </div>
                <Container>
                    <CommentCollection
                        taxon={this.state.taxon + 'p' + this.state.currentPage}
                        updateComments={this.updateComments}
                        title={''}
                        containerStyle={'full-white'}
                        containerMaxHeight={'none'}
                    />
                </Container>
                <div className="w-100 px-4 py-2 d-flex flex-row flex-wrap align-items-center justify-content-center">
                    <Pagination
                        label={"TaxonCommentPaginationBottom"}
                        totalRecords={this.state.totalComments}
                        pageLimit={this.state.pageLimit}
                        pageNeighbours={0}
                        currentPage={this.state.currentPage}
                        pageLink={'/taxon-comments/' + this.state.taxon + '/'}
                        onPageChanged={this.onPageChanged} />
                </div>
            </div>
        )
    }
}

export default withRouter(TaxonComment);
