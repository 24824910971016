/*
  * This class is taken from
  * https://www.digitalocean.com/community/tutorials/how-to-build-custom-pagination-with-react
 */

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import './Pagination.css';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
}

export default class Pagination extends Component {
    constructor(props) {
        super(props);
        const { pageLimit = 30, pageNeighbours = 0, currentPage = 1 } = props;

        this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 30;

        // pageNeighbours can be: 0, 1 or 2
        this.pageNeighbours = typeof pageNeighbours === 'number'
            ? Math.max(0, Math.min(pageNeighbours, 2))
            : 0;

        this.pageLink = props.pageLink;

        this.state = {
            currentPage: currentPage,
            totalRecords: undefined,
            totalPages: undefined,
        };
    }

    updateState() {
        const totalPages = Math.ceil(this.props.totalRecords / this.pageLimit);
        this.setState({
            currentPage: this.props.currentPage,
            totalRecords: this.props.totalRecords,
            totalPages: totalPages,
        })
    }

    /**
     * Let's say we have 10 pages and we set pageNeighbours to 2
     * Given that the current page is 6
     * The pagination control will look like the following:
     *
     * (1) < {4 5} [6] {7 8} > (10)
     *
     * (x) => terminal pages: first and last page(always visible)
     * [x] => represents current page
     * {...x} => represents page neighbours
     */
    fetchPageNumbers = () => {
        const totalPages = this.state.totalPages;
        const currentPage = this.state.currentPage;
        const pageNeighbours = this.pageNeighbours;

        /**
         * totalNumbers: the total page numbers to show on the control
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
         */
        const totalNumbers = (this.pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages <= totalBlocks) {
            return range(1, totalPages);
        }

        const startPage = Math.max(2, currentPage - pageNeighbours);
        const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
        let pages = range(startPage, endPage);

        /**
         * hasLeftSpill: has hidden pages to the left
         * hasRightSpill: has hidden pages to the right
         * spillOffset: number of hidden pages either to the left or to the right
         */
        const hasLeftSpill = startPage > 2;
        const hasRightSpill = (totalPages - endPage) > 1;
        const spillOffset = totalNumbers - (pages.length + 1);

        switch (true) {
            // handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
            const extraPages = range(startPage - spillOffset, startPage - 1);
            pages = [LEFT_PAGE, ...extraPages, ...pages];
            break;
        }

            // handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill): {
            const extraPages = range(endPage + 1, endPage + spillOffset);
            pages = [...pages, ...extraPages, RIGHT_PAGE];
            break;
        }

            // handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
            pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
            break;
        }
        }

        return [1, ...pages, totalPages];
    }

    componentDidMount = async () => {
        await this.updateState();
        this.gotoPage(this.state.currentPage);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.totalRecords !== this.props.totalRecords || prevProps.currentPage !== this.props.currentPage) {
            this.updateState();
        }
    }

    getCorrectPage = (page) => {
        return Math.max(1, Math.min(page, this.state.totalPages));
    }

    gotoPage = (page) => {
        const { onPageChanged = f => f } = this.props;
        const currentPage = this.getCorrectPage(page);
        const paginationData = {
            currentPage: currentPage,
            totalPages: this.state.totalPages,
        };

        this.setState({ currentPage }, () => onPageChanged(paginationData));
    }

    getPageLink = (page) => {
        const correctPage = this.getCorrectPage(page);
        return this.pageLink + correctPage;
    }

    getPageLinkLeft = () => {
        const newPage = this.state.currentPage - (this.pageNeighbours * 2) - 1
        return this.pageLink + newPage
    }

    getPageLinkRight = () => {
        const newPage = this.state.currentPage + (this.pageNeighbours * 2) + 1
        return this.pageLink + newPage
    }

    handleClick = page => evt => {
        evt.preventDefault();
        this.gotoPage(page);
    }

    handleMoveLeft = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage - (this.pageNeighbours * 2) - 1);
    }

    handleMoveRight = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage + (this.pageNeighbours * 2) + 1);
    }

    render() {
        if (!this.state.totalRecords || this.state.totalPages === 1) {
            return null;
        }

        const currentPage = this.state.currentPage;
        const pages = this.fetchPageNumbers();

        return (
            <Fragment>
                <nav aria-label={this.props.label}>
                    <ul className="pagination">
                        { pages.map((page, index) => {
                            if (page === LEFT_PAGE) return (
                                <li key={index} className="page-item">
                                    <Link to={this.getPageLinkLeft} className="page-link" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only">Previous</span>
                                    </Link>
                                </li>
                            );
                            if (page === RIGHT_PAGE) return (
                                <li key={index} className="page-item">
                                    <Link to={this.getPageLinkRight} className="page-link" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                        <span className="sr-only">Next</span>
                                    </Link>
                                </li>
                            );

                            return (
                                <li key={index} className={`page-item${ currentPage === page ? ' active' : ''}`}>
                                    <Link to={this.getPageLink(page)} className="page-link">
                                        { page }
                                    </Link>
                                </li>
                            );
                        }) }

                    </ul>
                </nav>
            </Fragment>
        );
    }
}
