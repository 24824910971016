import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../contexts/user.context';

import '../index.css';
import './Profile.css';

function DisplayProfile(props) {
    const {t} = useTranslation();
    const fields = [
        {
            key: 'Username',
            val: props.user.username,
        },
        {
            key: 'First name',
            val: props.user.firstname,
        },
        {
            key: 'Last name',
            val: props.user.lastname,
        },
        {
            key: 'Email',
            val: props.user.email,
        },
    ];

    const rows = fields.map((field, fidx) => {
        if (!field.val) {
            return null;
        }
        const text = field.val.toString();

        return (
            <tr key={fidx}>
                <td key={'profile-key' + fidx} className='profile-prop'>{t(field.key, field.key)}: </td>
                <td key={'profile-val' + fidx} className='profile-val'>
                    {text}
                </td>
            </tr>
        );
    });

    return (
        <div className='profile'>
            <table id="profile">
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>
    )
}

function Profile(props) {
    const user = useContext(UserContext);
    const {t} = useTranslation();
    if (user.user === null){
        return null;
    }
    return (
        <div className="full-white">
        <h3 className='component-title'>{t("Profile","Profile")}</h3>
        <DisplayProfile
            user={user.user}
        />
        </div>
    )

}

export default Profile;
