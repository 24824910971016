import { useContext, useState } from 'react';
import { Button, Form, Modal} from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { AuthService } from '../../services/auth.service';
import { UserContext } from '../../contexts/user.context';


function SignInControl(props) {
    const user = useContext(UserContext);
    const authService = new AuthService();
    const handleSignIn = async (username, password) => {
        const ret = await authService.login(username, password, () => { user.updateContext(); props.handleSubmit(); });
        return ret;
    }

    return (
        <SignInForm
            onHide={props.onHide}
            handleSignIn={handleSignIn} />
    )
}

function SignInForm(props) {
    const [loginSuccess, setLoginSuccess] = useState(true);

    const onSuccess = async (values) => {
        const ret = await props.handleSignIn(values.username, values.password);
        setLoginSuccess(ret === 0)
    };

    const validateUsername = (value) => {
        let error;
        if (value === null || value === '') {
            error = "Username is required"
        } else if (value.length > 32) {
            error = "Must be 32 characters or less"
        }

        return error;
    };

    const validatePassword = (value) => {
        let error;
        if (value === null || value === '') {
            error = "Password is required"
        }

        return error;
    };

    const handleCredentialChange = (e, field) => {
        field.onChange(e);
        setLoginSuccess(true);
    }

    const {t} = useTranslation();

    return (
        <Formik
            onSubmit={onSuccess}
            initialValues={{
                username: '',
                password: '',
            }}>

            {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
            }) => {
                return (
                    <Form noValidate className="loginForm" onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Field
                                name="username"
                                validate={validateUsername}>
                                {({ field, formProps }) => (
                                    <Form.Group controlId="username">
                                        <Form.Label>{t("Username", "Username")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t("Enter username", "Enter username")}
                                            value={field.value}
                                            onChange={(e) => {handleCredentialChange(e, field)}}
                                            isInvalid={touched.username && errors.username} />
                                        {touched.username && errors.username &&
                                            <Form.Control.Feedback type="invalid">
                                                {errors.username}
                                            </Form.Control.Feedback>}
                                    </Form.Group>
                                )}
                            </Field>

                            <Field
                                name="password"
                                validate={validatePassword}>
                                {({ field, formProps }) => (
                                    <Form.Group controlId="password">
                                        <Form.Label>{t("Password", "Password")}</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder={t("Enter password", "Enter password")}
                                            value={field.value}
                                            onChange={(e) => {handleCredentialChange(e, field)}}
                                            isInvalid={touched.password && errors.password}
                                        />
                                        {touched.password && errors.password &&
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password}
                                            </Form.Control.Feedback>}

                                        {!loginSuccess &&
                                            <span className='invalid-feedback' style={{display: 'block'}}>
                                                {t("Incorrect username or password")}
                                            </span>
                                        }
                                    </Form.Group>
                                )}
                            </Field>

                        </Modal.Body>
                        <Modal.Footer style={{justifyContent: 'center'}}>
                            <Button variant="primary" type="submit">{t("Submit", "Submit")}</Button>
                            <div style={{ width: "250px" }}></div>
                            <Button variant="outline-secondary" onClick={props.onHide}>{t("Cancel", "Cancel")}</Button>
                        </Modal.Footer>
                    </Form>
                );
            }}
        </Formik>
    )
}

export function LoginModal(props) {
    const handleSubmit = () => {
        props.onHide();
    };

    const {t} = useTranslation();

    return (
      <Modal
        {...props}
        animation={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("Sign In", "Sign In")}
          </Modal.Title>
        </Modal.Header>
          <SignInControl
            onHide={props.onHide}
            handleSubmit={handleSubmit}/>
      </Modal>
    );
  }
