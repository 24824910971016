import React, { useContext, Component, Suspense, lazy } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import { useTranslation } from 'react-i18next';
import Gallery from './components/Gallery';
import Image from './components/Image';
import TaxonImage from './components/TaxonImage';
import TaxonComment from './components/TaxonComment';
import UnknownClassification from './components/UnknownClassification';

import { Header } from './components/header/Header';
import Footer from './components/Footer';

import ClassificationContextProvider from './contexts/classification.context'
import UserContextProvider from './contexts/user.context'
import { UserContext } from './contexts/user.context';
import About from "./components/About";
import Profile from "./components/Profile";
import { Registration } from "./components/Registration";

import './App.css';

const ImageEdit = lazy(() => import('./components/ImageEdit'));

function ProtectedRoute() {
    const user = useContext(UserContext);
    const role = user.user ? user.user.role : '';

    return (
        role === "editor" ?
            <Switch>
                <Route path="/image-edit/:id"       component={ImageEdit}             name="image-edit" />
                <Route path="/image-new/"           component={ImageEdit}             name="image-new" />
            </Switch>
            : null
    )
}

function UploadButton() {
    const user = useContext(UserContext);
    const role = user.user ? user.user.role : '';

    const {t} = useTranslation();
    return (
        role === "editor" ?
            <span className='upload component-title'>
                <Link to="/image-new/">{t("upload", "upload")}</Link>
            </span>
            : null
    )
}

function Page() {

    return (
            <UserContextProvider>
            <ClassificationContextProvider>
                <Router>
                    <div className="App">
                        <div id="wrap">
                            <Header />
                            <UploadButton />
                            <div id="content">
                                <Switch>
                                    <Route path="/" exact               component={Gallery} />
                                    <Route path="/about"                component={About}                 name="about"/>
                                    <Route path="/profile"              component={Profile}               name="profile"/>
                                    <Route path="/registration"         component={Registration}          name="registration"/>
                                    <Route path="/gallery/index" exact  component={Gallery} />
                                    <Route path="/gallery/index/id/:id" component={Gallery}               name="gallery"/>
                                    <Route path="/image/:id"            component={Image}                 name="image"/>
                                    <Route path="/taxon-images/:id/" exact component={TaxonImage} />
                                    <Route path="/taxon-images/:id/:page"  component={TaxonImage}         name="taxon-images"/>
                                    <Route path="/taxon-comments/:id/" exact component={TaxonComment} />
                                    <Route path="/taxon-comments/:id/:page"  component={TaxonComment}     name="taxon-comments"/>
                                    <Route path="/unknown/:id"          component={UnknownClassification} name="unknown"/>
                                    <ProtectedRoute/>
                                </Switch>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </Router>
            </ClassificationContextProvider>
            </UserContextProvider>
    );
}

class App extends Component {
    componentDidMount() {
        document.title = "Als Photo Page";
    }

    render() {
        return (
            <Suspense fallback={null}>
                <Page />
            </Suspense>
        );
    }
}

export default App;
