import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { Field, Formik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

import './Registration.css';

function Captcha(props) {
    const { i18n } = useTranslation();
    return(
        <ReCAPTCHA
            hl={i18n.language}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
            onChange={props.handleSuccess}/>
    )
}

function validateUsername(value) {
    let error;
    if (value === null || value === '') {
        error = "Username cannot be empty"
    } else if (value.length > 32) {
        error = "Must be 32 characters or less"
    } else if (value.length < 3) {
        error = "Username is too short"
    } else if (value[0] === ' ') {
        error = "Username cannot start with a whitespace"
    } else if (value[value.length-1] === ' ') {
        error = "Username cannot end with a whitespace"
    }

    return error;
}

function validatePassword(value) {
    let error;
    if (value === null || value === '') {
        error = "Password cannot be empty"
    } else if (value.length < 4) {
        error = "Sorry, your password is too short"
    }
    else if (value.length > 128) {
        error = "Sorry, your password is too long"
    }

    return error;
}

function validateEmail(value) {
    return null;
}

async function register(values, callback) {
    const {captchaToken, ...payload} = values;

    const headers = {recaptcha: captchaToken}

    let ret = 0;

    await axios.post('/api/auth/register', payload, { headers })
        .then(res => {
            // Registration has been successfull
            ret = res.data.status
            if (ret === 0) {
                callback();
            }
        })
        .catch(err => {
            ret = 100;
            console.log("Error:", err)
        })
    return ret;
}

function RegistrationForm() {
    const { t } = useTranslation();

    const [done, setDone] = useState(false);
    const [regReturn, setRegReturn] = useState(0);

    const callback = () => { setDone(true); }

    const onRegister = async (values) => {
        const ret = await register(values, callback);
        if (ret !== 0) {
            window.grecaptcha.reset();
        }
        setRegReturn(ret);
    }

    const handleFormChange = (e, field) => {
        field.onChange(e);
        setRegReturn(0);
    }

    if (done === true) {
        const successText = t("Registration is successfull", "Registration is successfull.  You may now log in.");
        return (
            <Alert variant="success">
                {successText}
            </Alert>
        )
    }

    return (
        <Formik initialValues={{
            username: '',
            password: '',
            email: '',
            firstname: '',
            lastname: '',
            captchaToken: null
        }}
            onSubmit = {onRegister}
        >
            {({values, touched, errors, handleSubmit, handleChange, setFieldValue}) => { return(
            <Form noValidate className="registrationForm" onSubmit={handleSubmit}>
                <Field name="username" validate={validateUsername}>
                    {({field}) => { return (
                    <Form.Group controlId="username">
                        <Form.Label>{t("Username", "Username")}</Form.Label>
                        <Form.Control type="text"
                                      placeholder={`${t("Choose your username", "Choose your username")}`}
                                      value={field.value}
                                      onChange={(e) => {handleFormChange(e, field)}}
                                      isInvalid={touched.username && errors.username}/>
                        {touched.username && errors.username &&
                            <Form.Control.Feedback type="invalid">
                                {t(errors.username)}
                            </Form.Control.Feedback>}
                        {regReturn === 1 &&
                            <span className='invalid-feedback' style={{display: 'block'}}>
                                {t("The username is taken","The username is taken. Please try another.")}
                            </span>
                        }
                        {regReturn === 3 &&
                            <span className='invalid-feedback' style={{display: 'block'}}>
                                {t("Invalid username", "Invalid username. Please try another.")}
                            </span>
                        }
                    </Form.Group>
                    )}}
                </Field>

                <Field name="password" validate={validatePassword}>
                    {({field}) => {return (
                    <Form.Group controlId="password">
                        <Form.Label>{t("Password", "Password")}</Form.Label>
                        <Form.Control type="password"
                                      placeholder={`${t("Enter password", "Enter password")}`}
                                      value={field.value}
                                      onChange={(e) => {handleFormChange(e, field)}}
                                      isInvalid={touched.password && errors.password}/>
                        {touched.password && errors.password &&
                            <Form.Control.Feedback type="invalid">
                                {t(errors.password)}
                            </Form.Control.Feedback>}
                        {regReturn === 4 &&
                            <span className='invalid-feedback' style={{display: 'block'}}>
                                {t("Invalid password","Invalid password. Please try another.")}
                            </span>
                        }
                    </Form.Group>
                    )}}
                </Field>

                <Field name="email" validate={validateEmail}>
                    {({field}) => { return (
                    <Form.Group controlId="email">
                        <Form.Label>{t("Email", "Email address")}</Form.Label>
                        <Form.Control type="email"
                                      placeholder={`${t("Enter email", "Enter email")}`}
                                      value={field.value}
                                      onChange={field.onChange}/>
                        <Form.Text className="text-muted">
                            {t("Your email will not be visible to other users", "Your email will not be visible to other users")}
                        </Form.Text>
                        {regReturn === 2 &&
                            <span className='invalid-feedback' style={{display: 'block'}}>
                                {t("The email has been used","The email has been used. Please try another.")}
                            </span>
                        }
                    </Form.Group>
                    )}}
                </Field>

                <Field name="firstname" noValidate>
                    {({field}) => { return (
                    <Form.Group controlId="firstname">
                        <Form.Label>{t("First name", "First name")}</Form.Label>
                        <Form.Control type="text"
                                      placeholder={`${t("Your first name", "Your first name")}`}
                                      value={field.value}
                                      onChange={field.onChange}/>
                    </Form.Group>
                    )}}
                </Field>

                <Field name="lastname" noValidate>
                    {({field}) => {return (
                    <Form.Group controlId="lastname">
                        <Form.Label>{t("Last name", "Last name")}</Form.Label>
                        <Form.Control type="text"
                                      placeholder={`${t("Your last name", "Your last name")}`}
                                      value={field.value}
                                      onChange={field.onChange}/>
                        {regReturn > 4 &&
                            <span className='invalid-feedback' style={{display: 'block'}}>
                                {t("Unknown error occurs","Unknown error occurs. Please try again.")}
                            </span>
                        }
                    </Form.Group>
                    )}}
                </Field>

                <div className="mb-3"><Captcha handleSuccess={ token => { setFieldValue("captchaToken", token); } }/></div>

                <Button variant="primary" type="submit">
                    {t("Register", "Register")}
                </Button>
            </Form>
            )}}
        </Formik>
    )
}

export function Registration() {

    const { t } = useTranslation();

    return (
        <Container>
            <h1 className="mb-4">{t("Registration", "Registration")}</h1>
            <RegistrationForm/>
        </Container>
    )
}
