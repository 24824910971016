import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import getHierarchyByTaxonID from './HierarchyByID';
import ClassificationHierarchy from './ClassificationHierarchy';
import Pagination from './Pagination';
import ImageCollection from './ImageCollection';
import TooltipComponent from './TooltipComponent';
import Loader from './Loader';

import { ClassificationContext } from '../contexts/classification.context';

import '../index.css';


class TaxonImage extends Component {
    static contextType = ClassificationContext;

    state = {
        rank: undefined,
        taxon: undefined,
        hierarchy: [],
        loading: true,
        timeout: false,
        currentPage: 1,
        totalImages: undefined,
        pageLimit: 100,
    }

    getImages = async (size, offset) => {
        let images = [];
        await axios.get(`/api/image/${this.state.rank}/${this.state.taxon}/${size}/${offset}`)
            .then(res => {
                images = res.data.slice;
                if (this.mounted) {
                    this.setState({
                        totalImages: res.data.total,
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
        return images;
    }

    getTotalImages = async () => {
        let totalImages = 0;
        await axios.get(`/api/image/${this.state.rank}/${this.state.taxon}/0/0`)
            .then(res => {
                totalImages = res.data.total;
            })
            .catch(err => {
                console.log(err)
            })
        return totalImages;
    }

    updateImages = async (currentImages, newSize) => {
        if (currentImages.length > 0) {
            return currentImages;
        }

        const images = await this.getImages(this.state.pageLimit, (this.state.currentPage - 1) * this.state.pageLimit);
        images.map(image => {
            image.url = '/image/' + image.id;
            image.full_name = image.tx.full_name;
            return image;
        });
        return images;
    }

    displayImage = (image, imageStyle) => {
        const proxy = '/data';
        return (
            <TooltipComponent
                id={image.id}
                name={image.full_name}
                gender={image.gender}
                reliability={image.reliability}
            >
                <img className='drop-shadow' style={imageStyle}
                    src={proxy + '/thumb/' + image.path_to_file}
                    alt={image.full_name}
                />
            </TooltipComponent>
        )
    }

    onPageChanged = data => {
        this.setState({
            currentPage: data.currentPage,
        });
    }

    updateCurrentPage = () => {
        var currentPage = 1
        if (this.props.match.params.page && isFinite(this.props.match.params.page)) {
            currentPage = Number(this.props.match.params.page);
        }
        this.setState({
            currentPage: currentPage,
        })
    }

    fetchContent = async () => {
        var id = '0'
        if (this.props.match.params.id) {
            id = this.props.match.params.id
        }

        const taxonInfo = await getHierarchyByTaxonID(id);

        this.setState({
            taxon: taxonInfo.taxon,
            rank: taxonInfo.rank,
            hierarchy: taxonInfo.hierarchy,
        }, async () => {
            const totalImages = await this.getTotalImages();
            this.setState({
                totalImages: totalImages,
                loading: false,
            })
        })

        this.context.updateHierarchy(taxonInfo.hierarchy);
        this.updateCurrentPage();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            clearTimeout(this.timeout);
            this.fetchContent();
        }
        else if (prevProps.match.params.page !== this.props.match.params.page) {
            clearTimeout(this.timeout);
            this.updateCurrentPage();
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.timeout = setTimeout(() => {
            this.setState({ timeout: true })
        }, 5000);

        this.fetchContent();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        this.mounted = false;
    }

    render() {
        if (this.state.loading) {
            if (!this.state.timeout)
                return null;
            return (
                <Loader />
            );
        }

        return (
            <div>
                <ClassificationHierarchy
                    hierarchy={this.state.hierarchy}
                />
                <div className="w-100 px-4 py-2 d-flex flex-row flex-wrap align-items-center justify-content-center">
                    <Pagination
                        label={"TaxonImagePaginationTop"}
                        totalRecords={this.state.totalImages}
                        pageLimit={this.state.pageLimit}
                        pageNeighbours={0}
                        currentPage={this.state.currentPage}
                        pageLink={'/taxon-images/' + this.state.taxon + '/'}
                        onPageChanged={this.onPageChanged} />
                </div>
                <ImageCollection
                    taxon={this.state.taxon + 'p' + this.state.currentPage}
                    updateImages={this.updateImages}
                    displayImage={this.displayImage}
                    title={''}
                    containerStyle={'full-white'}
                    containerMaxHeight={'none'}
                    containerMargin={32}
                    imageWidth={150}
                    imageHeight={150}
                    caption={false}
                />
                <div className="w-100 px-4 py-2 d-flex flex-row flex-wrap align-items-center justify-content-center">
                    <Pagination
                        label={"TaxonImagePaginationBottom"}
                        totalRecords={this.state.totalImages}
                        pageLimit={this.state.pageLimit}
                        pageNeighbours={0}
                        currentPage={this.state.currentPage}
                        pageLink={'/taxon-images/' + this.state.taxon + '/'}
                        onPageChanged={this.onPageChanged} />
                </div>
            </div>
        )
    }
}

export default withRouter(TaxonImage);
