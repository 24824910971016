import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

import ImageCollection from './ImageCollection';
import TooltipComponent from './TooltipComponent';
import '../index.css';


class RecentImage extends Component {
    state = {
        rank: this.props.rank,
        taxon: this.props.taxon,
    }

    getImages = async (size, offset) => {
        let images = [];
        await axios.get(`/api/image/${this.state.rank}/${this.state.taxon}/${size}/${offset}`)
            .then(res => {
                images = res.data.slice;
            })
            .catch(err => {
                console.log(err)
            })
        return images;
    }

    updateImages = async (currentImages, newSize) => {
        if (newSize > currentImages.length) {
            const images = await this.getImages(newSize-currentImages.length, currentImages.length);
            images.map(image => {
                image.url = '/image/' + image.id;
                return image;
            });
            return currentImages.concat(images);
        }
        return currentImages;
    }

    displayImage = (image, imageStyle) => {
        const proxy = '/data';
        return (
            <TooltipComponent
                id={image.id}
                name={image.tx.full_name}
                gender={image.gender}
                reliability={image.reliability}
            >
                <img className='drop-shadow' style={imageStyle}
                    src={proxy + '/thumb/' + image.path_to_file}
                    alt={image.tx.full_name}
                />
            </TooltipComponent>
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.taxon !== this.props.taxon || prevProps.containerMaxHeight !== this.props.containerMaxHeight) {
            this.setState({taxon: this.props.taxon, rank: this.props.rank});
        }
    }

    render() {
        const title = 'Recent Images';
        return (
            <ImageCollection
                taxon={this.props.taxon}
                updateImages={this.updateImages}
                displayImage={this.displayImage}
                title={this.props.t(title, title)}
                titleURL={this.props.titleURL}
                containerStyle={this.props.containerStyle}
                containerMaxHeight={this.props.containerMaxHeight}
                containerMargin={this.props.containerMargin}
                imageWidth={150}
                imageHeight={150}
                caption={false}
            />
        )
    }
}

export default withTranslation()(RecentImage);
