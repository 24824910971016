import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

import CommentCollection from './CommentCollection';
import '../index.css';


class RecentComment extends Component {
    state = {
        rank: this.props.rank,
        taxon: this.props.taxon,
    }

    getComments = async (offset) => {
        let comments = [];
        await axios.get(`/api/comments/${this.state.rank}/${this.state.taxon}/${offset}`)
            .then(res => {
                comments = res.data.slice;
            })
            .catch(err => {
                console.log(err)
            })
        return comments;
    }

    updateComments = async (currentComments) => {
        const newComments = await this.getComments(currentComments.length);
        return currentComments.concat(newComments);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.taxon !== this.props.taxon) {
            this.setState({taxon: this.props.taxon, rank: this.props.rank});
        }
    }

    render() {
        const title = 'Recent Comments';
        return (
            <CommentCollection
                taxon={this.state.taxon}
                updateComments={this.updateComments}
                title={this.props.t(title, title)}
                titleURL={this.props.titleURL}
                containerStyle={this.props.containerStyle}
                containerMaxHeight={this.props.containerMaxHeight}
            />
        )
    }
}


export default withTranslation()(RecentComment);
