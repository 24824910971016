import React, { Component, createContext } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios'

import { authHeader, hasAuthToken } from '../services/auth.service';

export const UserContext = createContext();

class UserContextProvider extends Component {
    state =
        {
            loading: true,
            user: null,
            updateContext: () => {this.updateUserContext();}
        }

    updateUserContext = () => {
        if (hasAuthToken()) {
            const headers = authHeader();
            axios.get(`/api/profile`, { headers })
                .then(res => {
                    this.setState({
                        loading: false,
                        user: res.data
                    })
                    if (res.data && res.data.lang) {
                        this.props.i18n.changeLanguage(res.data.lang);
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                })
        } else {
            this.setState({
                loading: false,
                user: null
            })
        }
    }

    componentDidMount() {
        this.updateUserContext();
    }

    render() {
        if (this.state.loading === true) {
            return (<div></div>)
        } else {
            return (
                <UserContext.Provider value={this.state}>
                    {this.props.children}
                </UserContext.Provider>
            )
        }
    }
}

export default withTranslation()(UserContextProvider);