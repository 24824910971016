import React, { Component, createContext } from 'react';
import { withTranslation } from 'react-i18next';

export const ClassificationContext = createContext();

class ClassificationContextProvider extends Component {
    state =
        {
            hierarchy: [],
            updateHierarchy: (h) => {this.updateHierarchy(h);}
        }

    updateHierarchy = (h) => {
        this.setState({
            hierarchy: h,
        })
    }

    render() {
        return (
            <ClassificationContext.Provider value={this.state}>
                {this.props.children}
            </ClassificationContext.Provider>
        )
    }
}

export default withTranslation()(ClassificationContextProvider);
