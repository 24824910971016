import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Form, FormControl } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import './Header.css';
import axios from 'axios';

const DEFAULT_FONT_STYLE="empty";

class SearchSuggestions extends Component {
    state = {
        suggestions: this.props.suggestions,
        selected: this.props.selected
    }

    componentDidUpdate() {
        if (this.state.selected !== this.props.selected) {
            this.setState({selected: this.props.selected});
        }
    }

    render() {
        let style = {};
        if (this.props.hidden === true) {
            style = {visibility: 'hidden'}
        }
        return (
            <div id="suggestions" style={style}>
                <ul id="slist">
                    {this.props.suggestions.map( (taxon, idx) => {
                        let suggestionItemClass = "suggestionItem";
                        if (this.state.selected !== undefined &&
                            this.state.selected === idx) {
                            suggestionItemClass += " selected";
                        }
                        return (<Link to={`/gallery/index/id/${taxon.id}`}
                                      onClick={this.props.handleClick}
                                      onMouseEnter={() => this.props.handleMouseEnter(idx)}
                                      onMouseLeave={this.props.handleMouseLeave}
                                      onMouseMove={()=> this.props.handleMouseMove(idx)}
                                      key={taxon.id}
                                      className="suggestionLink"><li  className={suggestionItemClass}
                        >{taxon.full_name}</li></Link>)
                    })}
                </ul>
            </div>)
    }
}

class SearchField extends Component {
    state = {
        searchString: this.props.t("search", "search"),
        savedString: '',
        mouseActive: false,
        default: true,
        focused: false,
        selectedIndex: undefined,
        classNames: DEFAULT_FONT_STYLE,
        candidates: []
    }

    makeQuery = (q) => {
        if (q.length > 2) {
            axios.get(`/api/taxon/search/${encodeURI(q)}`)
            .then(res => {
                this.setState({candidates: res.data})
            })
        } else {
            this.setState({candidates: []});
        }
    }

    setDefaultState = () => {
        this.setState({
            searchString: this.props.t("search", "search"),
            savedString: '',
            focused: false,
            mouseActive: false,
            selectedIndex: undefined,
            default: true,
            classNames: DEFAULT_FONT_STYLE,
            candidates: [] });
    }

    handleFocus = () => {
        if (this.state.default) {
            this.setState({
                searchString: "",
                default: false,
                focused: true,
                classNames: ""});
        }
        this.setState({focused: true});
    }

    handleBlur = (e) => {
        if (this.state.searchString === "") {
            this.setState({
                searchString: this.props.t("search", "search"),
                default: true,
                classNames: DEFAULT_FONT_STYLE
            })
        }
        if (e.relatedTarget === null ||
            e.relatedTarget.className !== "suggestionLink") {

            this.setState({focused: false});

        }
    }

    handleChange = (event) => {
        this.setState({
            searchString: event.target.value,
            savedString: event.target.value,
            mouseActive: false,
            selectedIndex: undefined,
        });
        this.makeQuery(event.target.value);
    }

    handleClick = () => {
        this.setDefaultState();
    }

    handleMouseMove = (idx) => {
        this.setState({mouseActive: true});

        if (idx !== this.state.selectedIndex) {
            this.setState({
                selectedIndex: idx,
                searchString: this.state.candidates[idx].full_name,
            })
        }
    }

    handleMouseEnter = (idx) => {
        if (this.state.mouseActive === true) {
            this.setState({
                selectedIndex: idx,
                searchString: this.state.candidates[idx].full_name,
            })
        }
    }

    handleMouseLeave = (idx) => {
        this.setState({
            selectedIndex : undefined,
            searchString: this.state.savedString
        })
    }

    handleKeyDown = (event) => {
        this.setState({mouseActive: false});
        // If there are no available suggestions,
        // do nothing.
        if (this.state.candidates.length === 0 ||
            this.state.focused === false) {
            return;
        }

        if (event.keyCode === 40) { // Down arrow
            if (this.state.selectedIndex === undefined) {
                this.setState({
                    selectedIndex : 0,
                    searchString: this.state.candidates[0].full_name,
                })
            } else {
                if (this.state.selectedIndex < this.state.candidates.length-1) {
                    this.setState({
                        selectedIndex : this.state.selectedIndex + 1,
                        searchString: this.state.candidates[this.state.selectedIndex+1].full_name,
                    });
                }
            }
            event.preventDefault();
        }

        if (event.keyCode === 38) { // Down arrow
            if (this.state.selectedIndex === undefined) {
                return
            } else {
                if (this.state.selectedIndex === 0) {
                    this.setState({
                        selectedIndex : undefined,
                        searchString: this.state.savedString
                    });
                }
                if (this.state.selectedIndex > 0 ) {
                    this.setState({
                        selectedIndex : this.state.selectedIndex - 1,
                        searchString: this.state.candidates[this.state.selectedIndex-1].full_name,
                    });
                }
                event.preventDefault();
            }
        }

        if (event.keyCode === 13) { // Enter key
            if (this.state.selectedIndex === undefined) {
                return;
            } else {
                this.setDefaultState();
                event.target.blur();
                const idx = this.state.selectedIndex;
                this.props.history.push(`/gallery/index/id/${this.state.candidates[idx].id}`);
                event.preventDefault();
            }
        }

    }

    createSuggestion = () => {
        let hiddenPredicate = true;
        if (this.state.focused &&
            this.state.default === false &&
            this.state.candidates.length >= 1) {

            hiddenPredicate = false;

        }
        return (<SearchSuggestions
                    handleClick={this.handleClick}
                    handleMouseEnter={this.handleMouseEnter}
                    handleMouseLeave={this.handleMouseLeave}
                    handleMouseMove={this.handleMouseMove}
                    selected={this.state.selectedIndex}
                    suggestions={this.state.candidates}
                    hidden={hiddenPredicate}/>)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.t !== this.props.t &&
            this.state.default === true) {
            this.setState({
                searchString: this.props.t("search", "search"),
            })
        }
    }

    render() {
        return (
            <Form inline className="justify-content-center mx-auto">
                <div className="search"
                    onBlur={this.handleBlur}
                    onMouseMove={this.handleMouseActive}
                    onKeyDown={this.handleKeyDown}>
                    <FormControl type='text'
                        size='sm'
                        id='search'
                        className={`${this.state.classNames} mx-auto`}
                        value={this.state.searchString}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        autoComplete="off" />
                    {this.createSuggestion()}
                </div>
            </Form>
        )
    }
}

export default withTranslation()(withRouter(SearchField));