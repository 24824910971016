import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../index.css';
import './ImageCollection.css';


function DisplayImageRow(props) {
    const imageContainerStyle = {
        marginLeft: props.colSpacing,
        width: props.imageWidth,
        height: props.imageHeight,
    };
    const withLeftMargin = {
        marginLeft: props.colSpacing,
    }
    const noLeftMargin = {
        marginLeft: 0,
    }
    const captionContainerStyle = {
        width: props.imageWidth,
    }
    const imageStyle = {
        maxWidth: props.imageWidth - 4,
        maxHeight: props.imageHeight - 4,
    }

    const row = props.images.map((image, idx) => {
        const marginStyle = idx === 0 ? noLeftMargin : withLeftMargin;
        return (
            <td key={image.id}>
                <div className='collection-image' style = {{...imageContainerStyle, ...marginStyle}} >
                    <Link to={image.url}>
                        {props.displayImage(image, imageStyle)}
                    </Link>
                </div>
                <div className='collection-caption' style = {{...captionContainerStyle, ...marginStyle}} >
                    { props.caption ?
                      <Link to={image.url}> {image.caption} </Link> : ''
                    }
                </div>
            </td>
        )
    });
    return (
        <tr>
            { row }
        </tr>
    );
}

function DisplayImages(props) {
    const numRows = Math.ceil(props.images.length / props.numCols)

    let rows = []
    for (let i = 0; i < numRows; ++i) {
        const fromIndex = props.numCols * i
        const toIndex = Math.min(fromIndex + props.numCols, props.images.length)
        rows.push(
            <DisplayImageRow
                images = {props.images.slice(fromIndex, toIndex)}
                imageWidth={props.imageWidth}
                imageHeight={props.imageHeight}
                caption={props.caption}
                displayImage={props.displayImage}
                colSpacing = {props.colSpacing}
                key = {i}
            />
        )
    }

    return (
        <table
            className='collection'
            style = { {maxHeight: props.containerMaxHeight} }
            onScroll={props.onScroll}>
            <tbody>
                { rows }
            </tbody>
        </table>
    );
}


export default class ImageCollection extends Component {
    state = {
        images: [],
        maxNumCols: 0,
        numCols: 0,
        colSpacing: 0,
        collectionWidth: 0,
        imageWidth: this.props.imageWidth,
        imageHeight: this.props.imageHeight,
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            this.updateImages(this.state.images.length + 2*this.state.maxNumCols,
                              this.state.maxNumCols,
                              this.state.collectionWidth);
        }
    }

    getNumCols = (numImages, maxNumCols) => {
        return Math.min(numImages, maxNumCols);
    }

    getSpacing = (numCols, collectionWidth) => {
        const maxColSpacing = 40;
        const colWidth = this.state.imageWidth+2;
        const colSpacing = Math.min(maxColSpacing,
                                  Math.round( (collectionWidth - numCols*colWidth) /
                                              (numCols+1) )
                                   );
        return colSpacing;
    }

    getMaxNumCols = (collectionWidth) => {
        const minColSpacing = 20;
        return Math.floor( (collectionWidth - minColSpacing) /
                           (this.state.imageWidth + 2 + minColSpacing));
    }

    updateWidth = () => {
        const collectionWidth = window.innerWidth - this.props.containerMargin;
        const maxNumCols = this.getMaxNumCols(collectionWidth);
        const minNumRows = this.props.containerMaxHeight === 'none' ? 3
              : Math.ceil(this.props.containerMaxHeight / this.state.imageHeight) + 1;
        const numRows = Math.max(minNumRows, Math.ceil(this.state.images.length / maxNumCols));

        this.setState({
            maxNumCols: maxNumCols,
            collectionWidth: collectionWidth,
        });

        if (numRows * maxNumCols !== this.state.images.length) {
            this.updateImages(numRows * maxNumCols, maxNumCols, collectionWidth);
        }
        else {
            const numCols = this.getNumCols(this.state.images.length, maxNumCols);
            const colSpacing = this.getSpacing(numCols, collectionWidth);
            this.setState({
                numCols: numCols,
                colSpacing: colSpacing,
            })
        }

    };

    updateImages = async (size, maxNumCols, collectionWidth) => {
        const images = await this.props.updateImages(this.state.images, size);
        const numCols = this.getNumCols(images.length, maxNumCols);
        const colSpacing = this.getSpacing(numCols, collectionWidth);
        if (this.mounted) {
            this.setState({
                images: images,
                numCols: numCols,
                colSpacing: colSpacing,
            });
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.updateWidth();
        window.addEventListener('resize', this.updateWidth);
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', this.updateWidth);
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.taxon !== this.props.taxon || prevProps.containerMaxHeight !== this.props.containerMaxHeight) {
            await this.setState({images: []});
            this.updateWidth();
        }
    }

    getTitle() {
        if (this.props.title && this.props.title.length > 0 && this.props.titleURL) {
            return (
                <span className='component-title' style={{padding: '0px'}}>
                    <Link to={this.props.titleURL}>
                        <h3 className='component-title'>{this.props.title}</h3>
                    </Link>
                </span>
            )
        }
        return (
            <h3 className='component-title'>{this.props.title}</h3>
        )
    }

    render() {
        if (this.state.images.length === 0) {
            return null;
        }
        return (
            <div className={this.props.containerStyle}>
                {this.getTitle()}
                <DisplayImages
                    images={this.state.images}
                    imageWidth={this.state.imageWidth}
                    imageHeight={this.state.imageHeight}
                    containerMaxHeight={this.props.containerMaxHeight}
                    caption={this.props.caption}
                    numCols={this.state.numCols}
                    colSpacing={this.state.colSpacing}
                    onScroll={this.handleScroll}
                    displayImage={this.props.displayImage}
                />
            </div>
        )
    }
}
