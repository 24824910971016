import axios from "axios";

export function hasAuthToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.access_token) {
        return true;
    } else {
        return false;
    }
}

export function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.access_token) {
        return { Authorization: 'Bearer ' + user.access_token };
    } else {
        return {};
    }
}

export class AuthService {
    async login(username, password, callback) {
        let ret = 0;
        await axios
            .post('/api/auth/login', { username, password })
            .then(res => {
                if (res.data.access_token) {
                    localStorage.setItem("user", JSON.stringify(res.data));
                }
                callback();
                ret = 0;
            })
            .catch(err => {
                console.log("Caught error: ", err);
                ret = 1;
            })
        return ret;
    }

    logout() {
        localStorage.removeItem("user");
    }
}
