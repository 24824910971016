import React from "react";
import { Link } from 'react-router-dom';

import TooltipComponent from './TooltipComponent';

import '../index.css';
import './CommentCollection.css';

function DisplaySingleComment(props) {
    const img_path = props.comment.im.path_to_file;
    const img_url = '/image/' + props.comment.im.id;
    const img_name = props.comment.im.tx.full_name;
    const proxy = '/data'

    return (
        <tr>
            <td key={'comment-image'+props.comment.id} className='comment-image'>
                <Link to={img_url}>
                    <TooltipComponent
                        id={props.comment.id}
                        name={img_name}
                     >
                        <img className='comment-thumbnail'
                            src={proxy + '/thumb/' + img_path} alt={img_name} />
                     </TooltipComponent>
                </Link>
            </td>
            <td key={'comment-body'+props.comment.id} className='comment-body'>
                <Link to={img_url} className='comment-body'>
                    <div className='comment-body' style={{padding:'0px'}}>
                        <p className='comment-header'>
                            <span className='comment-header-user'>
                                {props.comment.us.username}
                            </span>
                            <span className='comment-header-date'>
                                {new Intl.DateTimeFormat('en-GB', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric'
                                }).format(new Date(props.comment.date))}
                            </span>
                        </p>
                        <p className='comment-text'>
                            {props.comment.text !== null ?
                             props.comment.text.replace(/(?:\\(.))/g, '$1')
                             : null}
                        </p>
                    </div>
                </Link>
            </td>
        </tr>
    );
}

function DisplayComments(props) {
    const rows = props.comments.map((comment, idx) => (
            <DisplaySingleComment
                comment={comment} key={comment.id}
            />
    ));

    return (
        <table className='comment'
            style = { {maxHeight: props.containerMaxHeight} }
            onScroll={props.onScroll}>
            <tbody>
                { rows }
            </tbody>
        </table>
    );
}

export default class CommentCollection extends React.Component {
    state = {
        comments: [],
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            this.updateComments();
        }
    }

    updateComments = async () => {
        const updatedComments = await this.props.updateComments(this.state.comments)
        if (this.mounted) {
            this.setState({
                comments: updatedComments,
            });
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.updateComments();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.taxon !== this.props.taxon || prevProps.containerMaxHeight !== this.props.containerMaxHeight) {
            await this.setState({comments: []})
            this.updateComments();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getTitle() {
        if (this.props.title && this.props.title.length > 0 && this.props.titleURL) {
            return (
                <span className='component-title' style={{padding: '0px'}}>
                    <Link to={this.props.titleURL}>
                        <h3 className='component-title'>{this.props.title}</h3>
                    </Link>
                </span>
            )
        }
        return (
            <h3 className='component-title'>{this.props.title}</h3>
        )
    }

    render() {
        if (this.state.comments.length === 0) {
            return null;
        }

        return (
            <div className={this.props.containerStyle}>
                {this.getTitle()}
                <DisplayComments
                    comments={this.state.comments}
                    containerMaxHeight={this.props.containerMaxHeight}
                    onScroll={this.handleScroll}
                />
            </div>
        )
    }
}
