import { useTranslation } from 'react-i18next';

function Footer() {
    const {t} = useTranslation();
    const date = new Date();
    const year = date.getFullYear();
    return (
        <div id="footer">
            <p id="rightsp">&copy;{year} {t("All rights reserved")}</p>
        </div>
    )
}

export default Footer;