import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import getHierarchyByTaxonID from './HierarchyByID';
import ClassificationHierarchy from './ClassificationHierarchy';
import Successor from './Successor';
import Loader from './Loader';

import { ClassificationContext } from '../contexts/classification.context';

import '../index.css';


class UnknownClassification extends Component {
    static contextType = ClassificationContext;

    state = {
        rank: undefined,
        taxon: undefined,
        hierarchy: [],
        loading: true,
        timeout: false,
    }

    fetchContent = async () => {
        var id = '0'
        if (this.props.match.params.id) {
            id = this.props.match.params.id
        }
        const taxonInfo = await getHierarchyByTaxonID(id);

        if (this.mounted) {
            this.setState({
                taxon: taxonInfo.taxon,
                rank: taxonInfo.rank,
                hierarchy: taxonInfo.hierarchy,
                loading: false,
            })
            this.context.updateHierarchy(taxonInfo.hierarchy);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            clearTimeout(this.timeout);
            this.fetchContent();
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.timeout = setTimeout(() => {
            this.setState({ timeout: true })
        }, 5000);

        this.fetchContent();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        this.mounted = false;
    }

    render() {
        if (this.state.loading) {
            if (!this.state.timeout)
                return null;
            return (
                <Loader />
            );
        }

        return (
            <div>
                <ClassificationHierarchy
                    hierarchy={this.state.hierarchy}
                />
                <Successor
                    taxon={this.state.taxon}
                    name={'UNKNOWN'}
                    rank={this.state.rank}
                />
            </div>
        )
    }
}

export default withRouter(UnknownClassification);
